import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import AppInfo from 'AppInfo.json';

export default function AboutUs() {
  return (
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >
    <Grid item xs={12} sm={5}>
      <Paper sx={{padding: {xs: 3, md: 3}, bgcolor: "background.default"}}>
        <Grid container direction="column" alignItems="center" rowGap={3}>
          <Grid item>
            <Typography variant="h4" component="h2">
              About Us
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" component="h2">
              Dream. Make. Print.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" component="h2" align="center">
              Panoramix, LLC<br/>
              40 Cranston Ave<br/>
              Newport, RI 02840<br/>
              <br/>
              Version {AppInfo.version}-{AppInfo.buildNum}<br/>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>      
  </Grid>); 
}

import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { forwardRef, useImperativeHandle, useState }  from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RenderImage from 'components/RenderImage.js';

// eslint-disable-next-line
export default DialogRender = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    open() {
      handleOpenRender();
    }
  }));

  const [openRender, setOpenRender] = useState(false);

  const handleOpenRender = () => {
    setOpenRender(true);
  };

  const handleCloseRender = () => {
    setOpenRender(false);
    if (props?.closeCb) {
      props.closeCb();
    }
  };

  return (
    <Dialog
      open={openRender}
      onClose={handleCloseRender}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='md'
      fullwidth='true'
    >
      <DialogTitle id="alert-dialog-title">
        {"Create Image"}
        <IconButton
          aria-label="close"
          onClick={handleCloseRender}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent >
        <RenderImage isFlex={true}/>
      </DialogContent>
    </Dialog>
  );
});

function DialogRender(props) {
}
import { useContext, useEffect, useState }  from 'react';
import { useSearchParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, Divider, Paper, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { axApiContext, UserContext } from 'UserContext';

const defaultReceipt = {
  orderNum: "",
  date: "",
  shippingAddress: {
    firstName: ""
  },
  billingAddress: {
    firstName: ""
  },
  subtotal: "$0.00",
  shipping: "$0.00",
  tax: "$0.00",
  total: "$0.00",
  items: []
}

export default function OrderHistoryItem() {
  const [receipt, setReceipt] = useState(defaultReceipt)
  const [searchParams, setSearchParams] = useSearchParams();
  const uc = useContext(UserContext);

  useEffect(() => {
    const uuid = searchParams.get('uuid');
    if (uuid) {
      updateReceipt(uuid);
    }
  }, []);

  const updateReceipt = async (uuid) => {
    const _receipt = await axApiContext(uc, 'GET', '/cart/receipt/' + uuid);
    setReceipt(_receipt);
  }

  const formatDate = (sdt) => {
    const dt = new Date(Date.parse(sdt));
    return dt.toLocaleString();
  }

  return (
    <div>
      <Grid container direction="column" spacing={1} margin={0.5}>
        <Grid item>
          <Box margin={2}>
            <Typography variant="h4">
              Order #{receipt?.orderNum}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{padding: {xs:0, md: 3}, bgcolor: "background.default"}}>
            <Grid container direction="column">
              <Grid item marginBottom={5}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Order #
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          {receipt.orderNum}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Date
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          {formatDate(receipt.date)}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Status
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          {receipt.status}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Billing Address
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          {receipt?.billingAddress?.address1}
                        </Box>
                        {receipt?.billingAddress?.address2 &&
                          <Box sx={{typography: 'body1'}}>
                            {receipt?.billingAddress?.address2}
                          </Box>
                        }
                        <Box sx={{typography: 'body1'}}>
                          {receipt?.billingAddress?.city}, {receipt?.billingAddress?.state} {receipt?.billingAddress?.postalCode}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Shipping Address
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          {receipt?.shippingAddress?.address1}
                        </Box>
                        {receipt?.shippingAddress?.address2 &&
                          <Box sx={{typography: 'body1'}}>
                            {receipt?.shippingAddress?.address2}
                          </Box>
                        }
                        <Box sx={{typography: 'body1'}}>
                          {receipt?.shippingAddress?.city}, {receipt?.shippingAddress?.state} {receipt?.shippingAddress?.postalCode}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item>
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Qty
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Item
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Price Ea.
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Divider></Divider>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { receipt?.items.map(item => (
                      <TableRow key={item?.uuid}>
                        <TableCell>
                          <Box sx={{typography: 'body1'}}>
                            {item?.quantity}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{typography: 'body1'}}>
                            {item?.product?.name}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{typography: 'body1'}}>
                            ${item?.product?.price}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Divider></Divider>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Sub-total
                        </Box>
                      </TableCell>
                      <TableCell>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          ${receipt?.subtotal}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Shipping
                        </Box>
                      </TableCell>
                      <TableCell>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          ${receipt?.shipping}
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Tax
                        </Box>
                      </TableCell>
                      <TableCell>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          ${receipt?.tax}
                        </Box>
                      </TableCell>
                    </TableRow> 
                    <TableRow>
                      <TableCell>
                        <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
                          Total
                        </Box>
                      </TableCell>
                      <TableCell>
                      </TableCell>
                      <TableCell>
                        <Box sx={{typography: 'body1'}}>
                          ${receipt?.total}
                        </Box>
                      </TableCell>
                    </TableRow> 
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>);
}

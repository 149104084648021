import { useContext, useState, createContext }  from 'react';
import { googleLogout } from '@react-oauth/google';
import { axApi, BadResponseError, Urls, UserInfoResponse } from 'utils/api';
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

export const userPersonaWorker : string = "worker";
export const userPersonaEndUser : string = "enduser";
export const loginKindEmail : string = "email";
export const loginKindGoogle : string = "google";
export const loginKindFacebook : string = "facebook";

const lsUserContextKey: string = "userContext";
const lsSelectedImageUuids: string = "selectedImages";

export const googleClientId = "682135582253-3asdrqq35t9tdtjbcbf4fbannmou3b2q.apps.googleusercontent.com";
export const fbClientId = "591566719433944";

export type UserContextInfo = {
  loggedIn:           boolean;
  email:              string | null;
  roles:              string[] | null;
  uid:                string | null;
  kind:               string | null; // email, google, facebook
  persona:            string | null; // consumer, worker
  accessToken:        string | null;
  refreshToken:       string | null;
  name:               string | null;
  selectedImageUuids: string[] | null;
}

const defaultUserContext: UserContextInfo = {
  loggedIn: false,
  email: null,
  roles: null, 
  uid: null, 
  kind: null, 
  persona: null, 
  accessToken: null,
  refreshToken: null,
  name: null,
  selectedImageUuids: [],
}

interface UserContextInterface {
  context: UserContextInfo,
  setContext: (context: UserContextInfo) => void
}

// eslint-disable-next-line no-unused-vars
export const UserContext = createContext<UserContextInterface | null>(null)

export const UserContextProvider = (props: any) => {

  // eslint-disable-next-line react/prop-types
  const { children } = props

  let storedContext : UserContextInfo = defaultUserContext
  const strStoredContext = localStorage.getItem(lsUserContextKey);
  if (strStoredContext) {
    storedContext = JSON.parse(strStoredContext)
  }

  // State to hold the context
  const [_context, _setContext] = useState(storedContext)

  // Wrap _setContext to store new context in localStorage
  const setContext = (context: UserContextInfo) => {
    localStorage.setItem(lsUserContextKey, JSON.stringify(context))
    _setContext(context)
  }

  const contextValue = {
    context: _context,
    setContext: setContext,
  }

  return (
    <UserContext.Provider value={contextValue}>
     {children}
    </UserContext.Provider>
  )
}

// signinWithEmail signs in user with email and password
export async function signinWithEmail(uc: UserContextInterface, email: string, password: string) : Promise<boolean> {      
  try {
    const response = await axApi<UserInfoResponse>("POST", Urls.userAuthToken, {email: email, password: password});
    const user: UserContextInfo = {
      email: email,
      roles: [userPersonaEndUser],
      uid: response.uid,
      loggedIn: true,
      kind: loginKindEmail,
      persona: userPersonaEndUser,
      accessToken: response.access,
      refreshToken: response.refresh,
      name: email,
      selectedImageUuids: [],
    }
    uc.setContext(user);
    return true;
  } catch (e) {
    if (e instanceof BadResponseError) {
      if (e.code == 401) {
        // bad u/p
      }
    } else {
      // other error
    }
    return false;
  }
}

// signinWithFB signs in with FB
export async function signinWithFB(uc: UserContextInterface, name: string, userId: string, accessToken: string, signedRequest: string) : Promise<boolean> {

  try {
    const response = await axApi<UserInfoResponse>("POST", Urls.userAuthFacebook, {email: userId + "@facebook.com", password: "noop", name: name, userId: userId, accessToken: accessToken, signedRequest: signedRequest});
    const user: UserContextInfo = {
      email: "",
      roles: [userPersonaEndUser],
      uid: response.uid,
      loggedIn: true,
      kind: loginKindFacebook,
      persona: userPersonaEndUser,
      accessToken: response.access,
      refreshToken: response.refresh,
      name: name,
      selectedImageUuids: [],
    }
    uc.setContext(user);
    return true;
  } catch (e) {
    if (e instanceof BadResponseError) {
      if (e.code == 401) {
        // bad u/p
      }
    } else {
      // other error
    }
    return false;
  }
}

// signinWithGoogle signs in with google
export async function signinWithGoogle(uc: UserContextInterface, credential: string, clientId: string) : Promise<boolean> {

  try {
    const credentialDecoded: any = jwt_decode(credential)
    const response = await axApi<UserInfoResponse>("POST", Urls.userAuthGoogle, {email: credentialDecoded.email, password: "noop", givenName: credentialDecoded.given_name, familyName: credentialDecoded.family_name, credential: credential, clientId: clientId});
    const user: UserContextInfo = {
      email: credentialDecoded.email, 
      roles: [userPersonaEndUser], 
      uid: response.uid, 
      loggedIn: true,
      kind: loginKindGoogle, 
      persona: userPersonaEndUser,
      accessToken: response.access,
      refreshToken: response.refresh,
      name: credentialDecoded.given_name,
      selectedImageUuids: [],
    }
    uc.setContext(user);
    return true;
  } catch (e) {
    if (e instanceof BadResponseError) {
      if (e.code == 401) {
        // bad u/p
      }
    } else {
      // other error
    }
    return false;
  }
}

// signupWithEmail signs up using API and configures global and local storage and returns logged in status
export async function signupWithEmail(uc: UserContextInterface, email: string, password: string) : Promise<boolean> {

  try {
    await axApi<UserInfoResponse>("POST", Urls.userAuthRegister, {email: email, password: password});
    return true;
  } catch (e) {
    if (e instanceof BadResponseError) {
      if (e.code == 401) {
        // bad u/p
      }
    }
    return false;
  }
}

// signupWithEmail signs up using API and configures global and local storage and returns logged in status
export async function signupWithEmailConfirm(uc: UserContextInterface, email: string, password: string, confirmCode: string) : Promise<boolean> {

  try {
    await axApi<UserInfoResponse>("PUT", Urls.userAuthRegisterConfirmEmail, {email: email, password: password, confirm_code: confirmCode});
    const response = await axApi<UserInfoResponse>("POST", Urls.userAuthToken, {email: email, password: password});
    const user: UserContextInfo = {
      email: email, 
      roles: [userPersonaEndUser], 
      uid: response.uid, 
      loggedIn: true, 
      kind: loginKindEmail, 
      persona: userPersonaEndUser,
      accessToken: response.access,
      refreshToken: response.refresh,
      name: email,
      selectedImageUuids: [],
    }
    uc.setContext(user);
    return true;
  } catch (e) {
    if (e instanceof BadResponseError) {
      if (e.code == 401) {
        // bad u/p
      }
    }
    return false;
  }
}

// signupWithFB signs in with FB
export async function signupWithFB(uc: UserContextInterface, name: string, userId: string, accessToken: string, signedRequest: string) : Promise<boolean> {
  return signinWithFB(uc, name, userId, accessToken, signedRequest);
}

// signupWithGoogle signs in with google
export async function signupWithGoogle(uc: UserContextInterface, credential: string, clientId: string) : Promise<boolean> {
  return signinWithGoogle(uc, credential, clientId);
}

// needed to handle FB logouts
declare global {
  interface Window {
      FB:any;
  }
}

// logout clears local and global storage and marks as logged out
export function logout(uc: UserContextInterface) {

  if (uc.context.kind == loginKindFacebook) {
    let FB = window.FB;
    FB?.logout();
  } else if (uc.context.kind == loginKindGoogle) {
    googleLogout();
  }

  uc.setContext(defaultUserContext)
  localStorage.clear();
}

export class AccessTokenError extends Error {
  constructor() {
    super("Missing access token");
  }
}

// axApiContext calls axApi with accessToken and refresh token
export async function axApiContext<responseType>(
  context: UserContextInterface|null|undefined,
  method: string, 
  path: string, 
  body: any|null=null,
  params: any|null=null): Promise<responseType> {
  
  let accessToken = context?.context?.accessToken as string;
  if (accessToken) {
    const accessTokenDecoded: any = jwt_decode(accessToken);
    const isExpired = dayjs.unix(accessTokenDecoded.exp).diff(dayjs()) < 1;

    if (isExpired) {
      const response: any = await axApi('POST', "/user/auth/token/refresh", {
        refresh: context?.context.refreshToken
      });
      accessToken = response.access
      context?.setContext({...context.context, accessToken: accessToken, refreshToken: response.refresh})
    }
  } else {
    throw new AccessTokenError();
  }

  const headers: any = {Authorization: "Bearer " + accessToken}
  return axApi(method, path, body, params, headers); 
}

// axApiContextOptional calls axApi with accessToken and refresh if available
export async function axApiContextOptional<responseType>(
  context: UserContextInterface|null|undefined,
  method: string, 
  path: string, 
  body: any|null=null,
  params: any|null=null): Promise<responseType> {

  if (context?.context.accessToken) {
    return axApiContext(context, method, path, body, params);
  } else {
    return axApi(method, path, body, params);
  }
}

// setSelectedImages stores uuids to localstorage
export function setSelectedImages(uuids: string[]) {
  localStorage.setItem(lsSelectedImageUuids, JSON.stringify(uuids))
}

// getSelectedImages gets list of uuids from localstorage
export function getSelectedImages(uuids: string[]) {
  try {
    const sUuids = localStorage.getItem(lsSelectedImageUuids);
    if (sUuids) {
      return JSON.parse(sUuids);
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

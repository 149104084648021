import { Paper, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useCallback, useContext, useEffect, useRef, useState }  from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ShopIcon from '@mui/icons-material/Shop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EditIcon from '@mui/icons-material/Edit';
import { Gallery as Gallery2 } from "react-grid-gallery";  // works well with react-image-lightbox
import { useNavigate } from 'react-router-dom';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import DialogRender from 'components/DialogRender';
import DialogUpload from 'components/DialogUpload';
import { axApiContext, getSelectedImages, setSelectedImages, UserContext } from 'UserContext';

// HACK: to get gallery working
var currentGalleryIndex = 0;

const overlayStyle = {
  "backgroundColor": "rgba(0, 0, 0, 0.8)",
  "maxHeight": "240px",
  "overflow": "hidden",
  "position": "absolute",
  "bottom": 0,
  "width": "100%",
  "color": "white",
  "padding": "2px",
  "fontSize": "90%"
}

export default function Gallery() {
  const navigate = useNavigate();
  const refDialogRender = useRef(null);
  const refDialogUpload = useRef(null);
  const lightGallery = useRef(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [images, setImages] = useState([])
  const [imageUuids, setImageUuids] = useState([]);
  const [areSelected, setAreSelected] = useState(false);
  const uc = useContext(UserContext);

  useEffect(() => {
    getImagesAndShowRenderIfNone();
  }, []);

  const getImagesAndShowRenderIfNone = async () => {
    const _images = await getImages();
    if (_images.length == 0) {
      handleRender();
    }
  }

  const getImages = async () => {
    const r = await axApiContext(uc, 'GET', '/galleryitems');
    const _images = [];
    const _uuids = [];
    const selectedUuids = await getSelectedImages();
    for (const image of r) {
      _images.push({
        src: image.url, 
        thumb: image.url, 
        width: 512, 
        height: 512, 
        thumbnailCaptionxx: (image.metadata?.prompt) ? image.metadata?.prompt.substring(0, 23) : "",
        isSelected: selectedUuids.includes(image.uuid),
        subHtml: `<h4>${(image.metadata?.prompt) ? image.metadata?.prompt.substring(0, 23) : ""}</h4>`,
        defaultContainerWidth: 10,
        customOverlay: (
          <div style={overlayStyle}>
            <div>{(image.metadata?.prompt) ? image.metadata?.prompt.substring(0, 23) : ""}</div>
          </div>
        )
      });
      _uuids.push(image.uuid);
    }
    setImages(_images);
    setImageUuids(_uuids);
    setAreSelected(selectedUuids.length > 0)

    return _images;
  }

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
      lightGallery.current?.openGallery(currentGalleryIndex);
    }
  }, []);

  const renderCloseCb = () => {
    getImages();
  }

  const handleSelect = (index) => {
    const _images = images.map((item, i) => {
      return (i == index) ? { ...item, isSelected: !item.isSelected } : { ...item, isSelected: false };
    })
    var _selectedImageUuids = [];
    for (const i in images) {
      if (_images[i].isSelected) {
        _selectedImageUuids.push(imageUuids[i]);
      }
    }
    setShowLightbox(false); // HACK: make sure doesn't show lightbox
    setSelectedImages(_selectedImageUuids);
    setImages(_images);
    setAreSelected(_selectedImageUuids.length > 0)
  }

  const handleProducts = () => {
    let s = []
    for (let i=0; i<images.length; i++) {
      if (images[i].isSelected) {
        s.push(imageUuids[i])
      }
    }
    navigate("/products");
  }

  const handleClick = (e) => {
    setShowLightbox(true);
    currentGalleryIndex = e;
    lightGallery?.current?.openGallery(e);
  }

  const handleRender = () => {
    refDialogRender.current.open()
  }

  const handleRemove = async () => {
    if (getSelectedImages().length > 0) {
      for (const imageUuid of getSelectedImages()) {
        await axApiContext(uc, 'DELETE', `/galleryitem/${imageUuid}`);
      }
      getImages();
    }
  }

  return (
    <>
      <Grid container direction="column" spacing={1} margin={0}>
        <Grid item>
          <Grid container direction="row" spacing={1} margin={1} alignItems="center">
            <Grid item>
              <Typography variant="h4">
                Gallery
              </Typography>
            </Grid>
            <Grid item onClick={handleRender}>
              <Tooltip title="Make image">
                <AddCircleIcon fontSize='large' />
              </Tooltip>
            </Grid>
            <Grid item onClick={() => { 
                refDialogUpload.current.open();              
              }}>
              <Tooltip title="Upload">
                <FileUploadIcon fontSize='large' />
              </Tooltip>
            </Grid>
            { images.length > 0 &&
              <Grid item onClick={() => { 
                    setShowLightbox(true);
                    lightGallery?.current?.openGallery();          
              }}>
                <Tooltip title="Zoom In">
                  <ZoomInIcon fontSize='large'></ZoomInIcon>
                </Tooltip>
              </Grid>
            }
            <Grid item onClick={handleProducts}>
              <Tooltip title="Shop">
                <ShopIcon fontSize='large'></ShopIcon>
              </Tooltip>
            </Grid>
            { images.length > 0 && areSelected &&
              <>
                <Grid item onClick={() => { 
                  navigate("/editor")              
                }}>
                  <Tooltip title="Edit">
                    <EditIcon fontSize='large' />
                  </Tooltip>
                </Grid>
                <Grid item onClick={handleRemove}>
                  <Tooltip title="Remove Image">
                    <DeleteIcon fontSize='large' />
                  </Tooltip>
                </Grid>
              </>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} >
          <Paper sx={{padding: {xs:1, md: 3}, bgcolor: "background.default"}}>
            { images.length
              ? <Gallery2 margin={0} images={images} onSelect={handleSelect} onClick={(e) => handleClick(e)}/>
              : <Grid container direction='row'>
                  <Grid sx={{mr: .5}}>
                    <Typography>Your gallery of images is empty, click</Typography>
                  </Grid>
                  <Grid>
                    <AddCircleIcon fontSize='small' />
                  </Grid>
                  <Grid sx={{ml: .5}}>
                    <Typography>to create an image</Typography>
                  </Grid>
                </Grid>
            }
          </Paper>
        </Grid>
        { showLightbox ?
        <Grid item>
          <div>
            <LightGallery 
              licenseKey='6000-0000-000-0001' 
              plugins={[lgThumbnail, lgZoom]} 
              dynamic={true} 
              onInit={onInit}
              dynamicEl= {images}
            >
            </LightGallery>
          </div>
        </Grid> : "" }
      </Grid>
      <DialogRender ref={refDialogRender} closeCb={renderCloseCb}></DialogRender>
      <DialogUpload ref={refDialogUpload}></DialogUpload>
    </>);
}
import { parse, stringify } from 'yaml'
import axios from 'axios';

import ApiConfig from './apiConfig.js';


export interface MockEntry {
  method: string;
  path: string;
  request: any | null
  response: any | null;
}

const mock = false;

export const Urls = {
  userAuthRegister: "/user/auth/register",
  userAuthRegisterConfirmEmail: "/user/auth/confirm_email",
  userAuthToken: "/user/auth/token",
  userAuthGoogle: "/user/auth/google",
  userAuthFacebook: "/user/auth/facebook",
  userForgotPassword: "/user/password/forgot",
  userChangePassword: "/user/password",
  dimage: "/dimage",
  gallery: "/gallery",
  cart: "/cart",
  cartStripeGetPrivateKey: "cart/payment/stripe/pk",
  cartStripePay: "cart/payment",
  cartReceipt: "cart/receipt",
  cartAddressBilling: "cart/address/billing",
  cartAddressShipping: "cart/address/shipping"
}

// TODO: load from mock.yaml file
const mocks: Array<MockEntry> = [
  {
    method: "GET",
    path: Urls.gallery,
    request: null,
    response: {
      uid: "asdf",
      createdAt: "2022-11-01 15:15:15",
      updatedAt: "2022-11-01 15:15:15",
      url: ""
    }
  },
  {
    method: "POST",
    path: Urls.userAuthRegister,
    request: {email: "jccroy@gmail.com"},
    response: {uid: "asdfasdf", persona: "enduser"}
  },
  {
    method: "POST",
    path: Urls.userAuthToken,
    request: {email: "jccroy@gmail.com"},
    response: {uid: "asdfasdf", persona: "enduser"}
  },
  {
    method: "POST",
    path: Urls.userAuthGoogle,
    request: {email: "jccroy@gmail.com"},
    response: {uid: "asdfasdf", persona: "enduser"}
  },
  {
    method: "POST",
    path: Urls.userAuthFacebook,
    request: {email: "jccroy@gmail.com"},
    response: {uid: "asdfasdf", persona: "enduser"}
  },
  {
    method: "POST",
    path: Urls.dimage,
    request: null,
    response: {uid: "asdfasdf"}
  },
  {
    method: "GET",
    path: Urls.dimage + "/asdfasdf",
    request: null,
    response: {status: "rendered", url: "https://source.unsplash.com/random?auto=format&w=400&dpr=2"}
  },
]

// HTTP return code error 
export class BadResponseError extends Error {
  code: number;
  constructor(code: number) {
    super(`Return code ${code}`);
    this.code = code;
  }
}

export async function axApi<responseType>(
  method: string, 
  path: string, 
  request: any|null=null,
  params: any|null=null,
  headers: any|null=null 
) : Promise<responseType> {
  if (mock) {
    // the idea with mock is that it matches method and path and if a request key is present
    // and does not match then throws 400 error
    console.log("mock request", method, path, request, params)
    for (let m of mocks) {
      if (m.method===method && m.path===path) {
        if (m.request) {
          for (const key in m.request) {
            if (key in request) {
              if (m.request[key] !== request[key]) {
                console.log("mock return error")
                throw new BadResponseError(400);
              }
            }
          }
        }
        console.log("mock return", JSON.stringify(m.response))
        return m.response as responseType;
      }
    }
    throw new BadResponseError(404);
  } else {
    const { data, status } = await axios.request<responseType>({
        baseURL: ApiConfig.baseUrl,
        url: path,
        method,
        params,
        data: request,
        headers
      }
    );
    if (![200, 201, 202].includes(status)) {
      throw new BadResponseError(status);
    }
    return data;
  }
}

export async function axPub<responseType>(
  path: string,
  format: string="json"
) : Promise<responseType> {
  if (format == "yaml") {
    const { data, status } = await axios.request({
      baseURL: window.location.origin,
      url: path,
      method: 'GET'
    }
    );
    return parse(data);
  } else {
    const { data, status } = await axios.request<responseType>({
        baseURL: window.location.origin,
        url: path,
        method: 'GET'
      }
    );
    return data;
  }
}

// api

export interface ApiConfigResponse {
  baseUrl: string;
}

// auth

export interface UserInfoResponse {
    username: string;
    persona: string;
    uid: string;
    access: string;
    refresh: string;
}

// dimage

export interface Dimage {
  uuid: string;
  user_uid: string;
  status: string;
  metadata: any;
  url: string;
}

export interface DimageAdd {
  uuid: string;
}

export interface DimageStatus {
  status: string;
  url: string;
}

// orders

export interface OrderItemImage {
  uuid: string | null;
  url: string;
  parms: any;
}

export interface Product {
  uuid: string;
  name: string;
  description: string;
  metadata: any;
  price: number;
}

export interface CartOrderItem {
  uuid: string | null;
  product: Product;
  quantity: string;
  price: number;
  total: number;
  images: OrderItemImage[];
}

export interface CartOrder {
  uuid: string | null;
  status: string 
  createdAt: string;
  updateAt: string;
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;
  items: CartOrderItem[];
}

// gallery

export interface GalleryItem {
  uuid: string;
  createAt: string;
  url: string;
  dimage: Dimage;
  metadata: any;
}


import { Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState }  from 'react';
import { axPub, Urls } from 'utils/api';
import { axApiContextOptional, UserContext } from 'UserContext';

export default function RenderImage(props) {
  const { children, isFlex, ...other } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [timer, setTimer] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState("https://source.unsplash.com/random?auto=format&w=400&dpr=2&r=" + Math.random());
  const [prompt, setPrompt] = useState("")
  const [prompts, setPrompts] = useState([]);
  const [promptValues, setPromptValues] = useState([]);
  const [progress, setProgress] = useState(0);
  const uc = useContext(UserContext);
  const blank = "none";

  useEffect(() => {  

    axPub("./di1Prompts.yaml", "yaml").then(data => {
      // add blanks to prompts in cast they don't want to select
      let pvs = [];
      for (let i=0; i < data.prompts.length; i++) {
        data.prompts[i].values.unshift(blank);
        pvs.push("");
      }
      setPrompts(data.prompts);
      setPromptValues(pvs);
    });

  }, []);

  const handleChange = (event, index) => {
    setPromptValues(
      promptValues.map((p, i) => {
        return i === index ? (event.target.value === blank ? "" : event.target.value) : p
      })
    )
  };

  const handleMakeImage = async () => {

    setProgress(0);
    setShowSpinner(true);
    const intervalMs = 1000;
    const startTime = Date.now();
    const maxSecs = 90;

    const prompt2 = prompt + " " + promptValues.join(" ")

    // call api to create image supplying metadata
    var timer;
    axApiContextOptional(
      uc,
      "POST",
      Urls.dimage,
      {metadata: {prompt: prompt2}}).then(responsePost => {

      setShowImage(false);

      timer = setInterval(() => {
        // call api until status is rendered
        axApiContextOptional(
          uc,
          "GET", 
          Urls.dimage + "/" + responsePost?.uuid).then(responseGet => {

          if (responseGet.status == "rendered") {
            setShowSpinner(false);
            clearInterval(timer);
            setImageUrl(responseGet.url);
            setShowImage(true);
          } else {
            setProgress((p) => (p + 5))
            const millis = Date.now() - startTime;
            if (millis > maxSecs * 1000) {
              // times up!
              clearInterval(timer);
              setShowSpinner(false);    
            }
          }
        }).catch(e => {
          clearInterval(timer);
          setShowSpinner(false);
        });

      }, intervalMs);
      setTimer(timer)
    });
  }

  return (
    <Box sx={{width: {xs: 250, md: 850}}} paddingTop={1}>
      <Grid container direction="row" spacing={1} wrap="wrap">
        <Grid item md={6}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TextField
                label="Prompt"
                placeholder="Describe your image"
                multiline
                fullWidth
                rows={4}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleMakeImage();
                    ev.preventDefault();
                  }
                }}
              />
            </Grid>  
            <Grid item>
              <Grid container direction="row" spacing={1}>
                {prompts.map((prompt, index) => (
                  <Grid item xs={6} md={6} key={index}>
                    <FormControl fullWidth>
                      <InputLabel>{prompt.title}</InputLabel>
                      <Select
                        value={promptValues[index]}
                        label={prompt.title}
                        onChange={(e) => handleChange(e, index)}
                      >
                        {prompt.values.map((v, index) => (
                          <MenuItem value={v} key={index}>{v}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                ))}
              </Grid>  
            </Grid>  
            <Grid item xs={1}>
              <Button variant="contained" onClick={handleMakeImage} sx={{width: 150, bottom: 3}} disabled={!prompt}>Go</Button>
            </Grid>            
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          {showImage ? <Box
            component="img"
            sx={{
              height: { xs: 250, md: 400 },
              width: { xs: 250, md: 400 },
            }}
            src={imageUrl}
          /> : <Box
          sx={{
            height: { xs: 250, md: 400 },
            width: { xs: 250, md: 400 },
            border: 1,
            borderColor: 'rgb(150, 150, 150)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px'
          }}
          >
            <Typography>
              Image
            </Typography>
          </Box> } 
          {showSpinner ? <LinearProgress sx={{width: {xs: 250, md: 400}, height: 8}} variant="determinate" value={progress}/>: ""}
        </Grid>
      </Grid>
    </Box>
  );
}
import { useContext, useEffect, useRef, useState }  from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, FormControl, InputLabel, MenuItem, Typography, Paper, Select } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';

import { axApi } from 'utils/api.tsx';
import { Crop } from 'components/Crop';
import { axApiContext, getSelectedImages, UserContext } from 'UserContext';
import placeholderImage from 'assets/default-placeholder.png';

export default function Product()  {
  const cropEl = useRef(null);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);
  const [productMap, setProductMap] = useState({});
  const [tags1_key, setTags1_key] = useState("");
  const [tags1_values, setTags1_values] = useState([]);
  const [tags2_key, setTags2_key] = useState("");
  const [tags2_values, setTags2_values] = useState([]);
  const [tag1_selection, setTag1_selection] = useState("");
  const [tag2_selection, setTag2_selection] = useState("");
  const [total, setTotal] = useState("");
  const [galleryImage, setGalleryImage] = useState(null);
  const [cropRect, setCropRect] = useState({x:0.2, y:0.2, w:0.6, h:0.6});
  const [previewRect, setPreviewRect] = useState({x:10, y:10, w:100, h:100});
  const params = useLocation();
  const uc = useContext(UserContext);
  const galleryImageSize = {w: 512, h: 512};
  const previewImageMaxWidth = 1000;
  const matches = useMediaQuery('(min-width:600px)');
  const reactImageMaxWidth = matches ? 500 : 280;
  const ratio = reactImageMaxWidth / previewImageMaxWidth;

  useEffect(() => {
    // TODO: can be an edit of existing cart item or a new item
    let groupKey = params.state?.groupKey;
    if (groupKey === undefined) {
      console.log("missing groupKey, using default Mugs");
      groupKey = "Mugs";
    }

    axApi("GET", "/products", "", {group: groupKey}).then(_products => {
      let _tags1_values = []
      let _tags2_values = []
      let _tags1_key = ""
      let _tags2_key = ""
      let _productMap = {}

      // walk through all the products and get all tags and make productMap
      for (const i in _products) {
        _productMap[_products[i].tag1_value + _products[i].tag2_value] = i;
        _tags1_key = _products[i].tag1_key;
        _tags2_key = _products[i].tag2_key;
        if (!(_tags1_values.includes(_products[i].tag1_value))) {
          _tags1_values.push(_products[i].tag1_value)
        }
        if (!(_tags2_values.includes(_products[i].tag2_value))) {
          _tags2_values.push(_products[i].tag2_value)
        }
      }
      setProducts(_products);
      setProduct(_products[0]);
      setProductMap(_productMap);
      setTags1_key(_tags1_key);
      setTags1_values(_tags1_values);
      setTags2_key(_tags2_key);
      setTags2_values(_tags2_values);
      setTag1_selection(_tags1_values.length > 0 ? _tags1_values[0] : '')
      setTag2_selection(_tags2_values.length > 0 ? _tags2_values[0] : '')
      updateTotal(1, _products[0].price);
      updateRects(_products[0])

      const selectedImages = getSelectedImages();
      if (selectedImages.length > 0) {
        // get the first selected
        axApiContext(uc, "GET", "/galleryitem/" + selectedImages[0]).then(_image => {
          setGalleryImage(_image);
        });
      } else {
        // otherwise get the first
        axApiContext(uc, "GET", "/galleryitems").then(_images => {
          if (_images.length > 0) {
            setGalleryImage(_images[0]);
          } else {
            // TODO: 
          }
        });        
      }
      setCropRect(getDefaultCropRect(_products[0]));
    });

  }, []);

  const getDefaultCropRect = (prod) => {
    const productAR = prod.preview_pos_px_h /prod.preview_pos_px_w;
    const imageAR = galleryImageSize.h / galleryImageSize.w;

    if (imageAR > productAR) {

      const x1 = 0;
      const w1 = galleryImageSize.w;
      const h1 = productAR * w1;
      const y1 = (galleryImageSize.h - h1) / 2;

      const x2 = 0;
      const w2 = w1 / galleryImageSize.h;
      const y2 = y1 / galleryImageSize.h;
      const h2 = h1 / galleryImageSize.h;

      return {x: x2, y:y2, w: w2, h: h2}

    } else {

      const y1 = 0;
      const h1 = galleryImageSize.h;
      const w1 = h1 / productAR;
      const x1 = (galleryImageSize.w - w1) / 2;

      const x2 = x1 / galleryImageSize.w;
      const w2 = w1 / galleryImageSize.h;
      const y2 = 0;
      const h2 = h1 / galleryImageSize.h;

      return {x: x2, y:y2, w: w2, h: h2}
    }
  }

  const updateRects = (_product) => {
      // TODO: set crop initial crop rect, also, should check size of image first? assume 800px in 400px view
      setPreviewRect({
        x: _product.preview_pos_px_x, 
        y: _product.preview_pos_px_y, 
        w: _product.preview_pos_px_w, 
        h: _product.preview_pos_px_h
      });
  }

  const updateTotal = (quantity, price) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    setTotal(formatter.format(quantity * price));
  }

  const handleChangeQuantity = (event) => {
    setQuantity(event.target.value);
    updateTotal(event.target.value, product.price)
  };

  const handleChangeParm1 = (event) => {
    setTag1_selection(event.target.value);
    const p = products[productMap[event.target.value + tag2_selection]];
    setProduct(p);
    updateRects(p)
    updateTotal(quantity, p.price);
    // TODO: adjust crop rect
  };

  const handleChangeParm2 = (event) => {
    setTag2_selection(event.target.value);
    const p = products[productMap[tag1_selection + event.target.value]];
    setProduct(p);
    updateTotal(quantity, p.price);
  };

  const handleOpenCrop = () => {
    cropEl.current.open();
  };

  const handleCloseCrop = (crop) => {
    setCropRect({x: crop.x, y: crop.y, w: crop.w, h: crop.h});
  }

  const handleAdd = () => {
    addCart();
  }

  const addCart = async () => {
    const body = {
      metadata: {
        cropRect
      },
      product_uuid: product.uuid,
      quantity,
      image_uuids: [
        galleryImage?.uuid
      ]
    }
    const r = await axApiContext(uc, 'POST', '/cart/item', body);
    navigate("/cart");
  }

  return (
    <div>
      <Grid container direction="column" spacing={1} margin={0.5}>
        <Grid item>
          <Grid container direction="row" spacing={1} margin={1} alignItems="center">
            <Grid item>
              <Typography variant="h4">
                {product.group_key}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} >
          <Paper sx={{padding: {xs:0, md: 3}, marginRight: 1, bgcolor: "background.default"}}>
            <Grid container direction="row" spacing={0} margin={1} sx={{flexWrap: "wrap"}}>
              <Grid item >
              <Grid container flex={6} direction="column" alignContent="center" sx={{mr: 10}}>
                <Box
                  sx={{
                    width: reactImageMaxWidth,
                    maxWidth: reactImageMaxWidth   
                  }}
                  position="relative" >

                  <Box
                    sx={{
                      width: previewRect.w * ratio,
                      height: previewRect.h * ratio,
                      overflow: "hidden",
                      position: "absolute",
                      left: `${previewRect.x * ratio}px`,
                      top: `${previewRect.y * ratio}px`
                    }}
                  >
                    <Box
                    component="img"
                    sx={{
                      width: previewRect.w / cropRect.w * ratio,
                      height: ((galleryImageSize.h / galleryImageSize.w) * previewRect.w) / cropRect.w * ratio,
                      position: "absolute",
                      left: `${(-1.0 * previewRect.w / cropRect.w) * cropRect.x * ratio}px`,
                      top: `${-1.0 * (((galleryImageSize.h / galleryImageSize.w) * previewRect.w) / cropRect.w) * cropRect.y * ratio}px`
                    }}
                    src={galleryImage?.url || placeholderImage}
                    ></Box>
                  </Box>

                  <Box
                    component="img"
                    sx={{
                      width: reactImageMaxWidth,
                      maxWidth: reactImageMaxWidth
                    }}
                    position="relative" left="1px" top="1px"
                    src={product.preview_image_url}
                  />

                </Box>
              </Grid>
              </Grid>
              <Grid item >
              <Grid container flex={6} direction="column" xs={5} spacing={3} margin={0} marginTop={1}>
                {tags1_key && (
                  <Grid item key={1}>
                    <Box sx={{ width: 200 }}>
                      <FormControl fullWidth key={1}>
                        <InputLabel id={tags1_key}>{tags1_key}</InputLabel>
                        <Select
                          label={tags1_key}
                          labelId={tags1_key}
                          id={tags1_key}
                          value={tag1_selection}
                          onChange={(e) => handleChangeParm1(e, 1)}
                        >
                          {tags1_values.map((select) => (
                          <MenuItem value={select} key={select}>{select}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
                {tags2_key && (
                  <Grid item key={1}>
                    <Box sx={{ width: 200 }}>
                      <FormControl fullWidth key={1}>
                        <InputLabel id={tags2_key}>{tags2_key}</InputLabel>
                        <Select
                          label={tags2_key}
                          labelId={tags2_key}
                          id={tags2_key}
                          value={tag2_selection}
                          onChange={(e) => handleChangeParm2(e, 2)}
                        >
                          {tags2_values.map((select) => (
                          <MenuItem value={select} key={select}>{select}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
                <Grid item>
                  <Box sx={{ width: 75 }}>
                    <FormControl fullWidth>
                      <InputLabel>Qty</InputLabel>
                      <Select
                        labelId="quantity"
                        id="quantity"
                        value={quantity}
                        label="Quantity"
                        onChange={handleChangeQuantity}
                      >
                        <MenuItem value={1} key={1}>1</MenuItem>
                        <MenuItem value={2} key={2}>2</MenuItem>
                        <MenuItem value={3} key={3}>3</MenuItem>
                        <MenuItem value={4} key={4}>4</MenuItem>
                        <MenuItem value={5} key={5}>5</MenuItem>
                        <MenuItem value={6} key={6}>6</MenuItem>
                        <MenuItem value={7} key={7}>7</MenuItem>
                        <MenuItem value={8} key={8}>8</MenuItem>
                        <MenuItem value={9} key={9}>9</MenuItem>
                        <MenuItem value={10} key={10}>10</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography variant="h8">
                    Price: {total}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={handleOpenCrop} >
                    Crop
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleAdd} >
                    Add to Cart
                  </Button>
                </Grid>
              </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Crop ref={cropEl} handleCropped={handleCloseCrop} cropRect={cropRect} imageUrl={galleryImage?.url || placeholderImage}></Crop>
    </div>);
}

import * as React from 'react';
import { IconButton } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useNavigate } from "react-router-dom";
import { useState, useRef }  from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import DialogComingSoon from '../components/DialogComingSoon';

export default function HamburgerMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dialogEl = useRef(null);

  const [openExplore, setOpenExplore] = React.useState(false);
  const [openCreators, setOpenCreators] = React.useState(false);
  const [openMyItems, setOpenMyItems] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let navigate = useNavigate();

  const handleNav = (path) => {
    setAnchorEl(null);
    collapse();
    navigate(path);
  }

  const handleClickExplore = () => {
    setOpenExplore(!openExplore);
    setOpenCreators(false);
    setOpenMyItems(false);
  }

  const handleClickCreators = () => {
    setOpenExplore(false);
    setOpenCreators(!openCreators);
    setOpenMyItems(false);
  }

  const collapse = () => {
    setOpenExplore(false);
    setOpenCreators(false);
    setOpenMyItems(false);
  }

  const handleClickDocumentation = () => {
    window.location.href = "https://github.com/pximage"
  }

  return (
    <div>
      <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
        <DragHandleIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List
          sx={{
            width: '100%',
            maxWidth: 350,
            boxShadow: 14,
            borderRadius: 2,
            p: 1,
            bgcolor: 'background.paper'
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={ () => handleNav("/") }>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton onClick={ () => handleNav("/search") }>
            <ListItemText primary="Search" />
          </ListItemButton>
          <ListItemButton onClick={ () => handleNav("/products") }>
            <ListItemText primary="Products" />
          </ListItemButton>
          <ListItemButton onClick={ () => handleNav("/cart") }>
            <ListItemText primary="Cart" />
          </ListItemButton>
          <ListItem disablePadding>
            <ListItemButton onClick={handleClickDocumentation}>
              <ListItemText primary="Documentation" ></ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={ () => handleNav("/contactus") }>
              <ListItemText primary="Contact Us / Support" ></ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={ () => handleNav("/aboutus") }>
              <ListItemText primary="About" ></ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <DialogComingSoon ref={dialogEl}></DialogComingSoon>
    </div>
  );
}

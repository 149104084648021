import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, IconButton, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRef, useEffect, useState }  from 'react';
import Carousel from 'react-material-ui-carousel';
import CookieConsent from "react-cookie-consent";
import { axPub, axApi } from 'utils/api';

import logo_black from 'assets/logox_black.svg'
import logo_white from 'assets/logox_white.svg'
import { lightPalette } from 'theme';
import { SigninDialog } from 'components/SigninDialog';
import { SignupDialog } from 'components/SignupDialog';
import RenderImage from 'components/RenderImage';
import { productsGet } from 'utils/api.tsx'

export default function HomeNoUser() {
  const signinEl = useRef(null);
  const signupEl = useRef(null);
  const [sliderImages, setSliderImages] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {

    axPub("./wwwSliderImages.yaml", "yaml").then(data => setSliderImages(data));
    axApi("GET", "./products", "", {grouped: "1"}).then(data => setProducts(data));
  
  }, []);

  const handleOpenSignin = () => {
    signinEl.current.open();
  }

  const handleOpenSignup = () => {
    signupEl.current.open();
  }

  const signedInUpUpdateHandler = () => {
  }

  function Pix(props)
  {
    return (
      <Carousel>
        { sliderImages.map( (item, i) => <Item key={i} item={item} imageUrl={item.imageUrl} /> ) }
      </Carousel>
    )
  }

  function Item(props)
  {
    return (
      <Box
        component="img"
        sx={{
          height: { xs: 200, md: 512 },
          width: { xs: 200, md: 512 },
        }}
        src={props.imageUrl}
      />
    )
  }

  return (
    <div>
      <Grid container direction="column">
        <Grid container padding={1} direction="row" justifyContent={"space-between"} style={{ color: lightPalette.text.main, backgroundColor: lightPalette.cover, borderBottom: '1px solid black' }}>
          <Grid item sx={{padding: 1.5}}>
            <Box
              component="img"
              sx={{
                height: 150,
                width: '100%'
              }}
              src={logo_black}
            />
          </Grid>
          <Grid item sx={{padding: 1.5}}>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <Button color="text" variant="text" onClick={handleOpenSignin}>Sign In</Button>
              </Grid>
              <Grid item>
                <Button color="text" variant="outlined" onClick={handleOpenSignup}>Get Started</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container padding={1} direction="row" justifyContent={"space-between"} sx={{height: { xs: 300, md: 600 }, paddingTop: 5}} style={{ color: lightPalette.text.main, backgroundColor: lightPalette.cover }}>
          <Grid item flex={{xs: 5, md: 6}}>
            <Pix/>
          </Grid>
          <Grid item flex={{xs: 7, md: 6}} justifyContent={"center"}>
            <Grid container padding={4} direction="column" justifyContent="center" sx={{height: '100%'}}>
              <Grid item alignSelf="flex-start">
                <Typography variant="cover">
                  Dream it.
                </Typography>
              </Grid>
              <Grid item alignSelf="center">
                <Typography variant="cover">
                  Make it.
                </Typography>
              </Grid>
              <Grid item alignSelf="flex-end">
                <Typography variant="cover">
                  Print it.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item align={"center"} sx={{width: '100%', marginTop: 5}}>
          <RenderImage isFlex={true} />
        </Grid>
        <Grid item align={"center"} sx={{width: '100%', marginTop: 5, padding: 5}}>

          <Grid container spacing={4} margin="3">
            {products.map((product) => (
              <Grid item key={product.group_key} xs={12} sm={4} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      //https://source.unsplash.com/random
                      //                    alt="random"
                    }}
                    image={product.showcase_image_url}
                    style={{backgroundColor: 'green'}}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {product.group_key}
                    </Typography>
                    <Typography>
                      {`starting at $${product.price} for ${product.name_hr}s`}
                    </Typography>
                    <Typography>
                      {product.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

        </Grid>
      </Grid>
      <SigninDialog ref={signinEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></SigninDialog>
      <SignupDialog ref={signupEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></SignupDialog>
      <CookieConsent
        location="bottom"
        buttonText="Okay"
        cookieName="consentCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </div>
  );
}

import React, { useState } from 'react'
import { blue, cyan, indigo, grey, orange, yellow, lightBlue } from '@mui/material/colors';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const lsKeyTheme = "appTheme";

export const lightPalette = {
  primary: {
    main: blue[500],  // NOTE: nice orange ff9b00
    primary: blue[500], 
    secondary: '#f50057',
  },
  divider: blue[200],
  text: {
    main: '#000000',
    primary: '#000000',
    secondary: grey[400],
  },
  background: {
    default: grey[100],
    paper: grey[300],
  },
  cover: '#a7e0fc'
}

const darkPalette = {
  primary: lightBlue,
  divider: lightBlue[300],
  text: {
    main: '#ffffff',
    primary: '#ffffff',
    secondary: indigo[200],
  },
  background: {
    default: '#2d2d2d',
    paper: grey[900],
  }
}

const darkBluePalette = {
  primary: lightBlue,
  divider: indigo[700],
  text: {
    main: '#ffffff',
    primary: '#ffffff',
    secondary: indigo[200],
  },
  background: {
    default: grey[900],
    paper: indigo[900],
  }
}

const typography = {
  cover: {
    fontSize: 44,
    fontWeight: 80,
    '@media (min-width:600px)': {
      fontSize: 78,
      fontWeight: 480
    },
    fontFamily: [
      "Signika Negative"
    ]
  },    
  fontFamily: [
    "Signika Negative",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "sans-serif",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol"
  ].join(',')
}

function getTheme(mode) {
  let t = {}
  if (mode == "light") {
    t = {
      palette: {mode: "light", ...lightPalette},
      typography: typography
    };
  } else {
    t = {
      palette: {mode: "dark", ...darkPalette},
      typography: typography
    };
  }
  return createTheme(t);
}

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext(
  {
    currentTheme: 'light',
    setTheme: null,
  },
)

const CustomThemeProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props

  // Read current theme from localStorage or maybe from an api
  const currentTheme = localStorage.getItem(lsKeyTheme) || 'light'

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(currentTheme)

  // Retrieve the theme object by theme name
  const theme = getTheme(themeName)

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeName = (name) => {
    localStorage.setItem('appTheme', name)
    _setThemeName(name)
  }

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  }

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  )
}

export const ClearLocalStorage = () => {
  localStorage.clear(lsKeyTheme);
}

export default CustomThemeProvider
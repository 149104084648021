import { AppBar, Button, Card, CardActions, CardContent, CardMedia, CssBaseline, Box, Toolbar, Typography, Container, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useState, useEffect }  from 'react';
import background from '../assets/photo-1566094260903-3a9f9f6bd107.jpeg'
import { axApiContext, UserContext } from 'UserContext';


export default function SearchResults() {
  const [results, setResults] = useState(null);
  const [searchText, setSearchText] = useState("");
  const uc = useContext(UserContext);

  useEffect(() => {
  }, [])

  const search = async () => {
    if (searchText) {
      const r = await axApiContext(uc, 'GET', `/galleryitems/search?search=${searchText}`);
      setResults(r);
    }
  }

  return (
    <>
      <Grid container direction="column" spacing={1} margin={0}>
      <CssBaseline/>

      <AppBar position="relative" 
        style={{
          backgroundImage: `url(${background})`, 
          height: 150, 
          width: '100%', 
          backgroundPosition: 'center 37%', 
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat'}}>
        <Toolbar>
          <Grid container sx={{width: '100%', height: '150px'}} direction="row" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Grid item xs={12} md={6}>
              <TextField 
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                onKeyDown={e => {if (e.keyCode === 13) search();}} 
                hiddenlabel="true"
                variant="standard"
                fullWidth
                sx={{
                  input: {color: "black"},
                  backgroundColor: "white"
                }}
              />        
            </Grid>
            <Grid item xs={12} md={2}>
              <Button 
                variant="contained" 
                fullWidth 
                onClick={search}>
                Search Prompts
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 1,
          pb: 0,
        }}
      >
      </Box>
      <Container sx={{ py: 4 }} maxWidth="md">
        <Grid container spacing={4}>
          {results && results.length == 0 &&
            <Box sx={{typography: 'body1', fontWeight: 'bold'}}>
              Oops, no results!  Try again...
            </Box>
          }
          {results && results.length > 0 && results.map((result) => (
            <Grid item key={result.uuid} xs={12} sm={9} md={6}>
              <Card
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    // 16:9
                    //https://source.unsplash.com/random
                    //                    alt="random"
                  }}
                  image={result["url"]}
                  style={{backgroundColor: 'green'}}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {result['metadata']['prompt']}
                  </Typography>
                  <Typography>
                    {result['description']}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </Grid>
    </>
  );
}
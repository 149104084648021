import { Typography, Button, Paper, Snackbar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState }  from 'react';

import TextField from '@mui/material/TextField';

import { axApiContext, UserContext } from 'UserContext';

export default function Account() {

  const [snackOpen, setSnackOpen] = useState(false);
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const uc = useContext(UserContext);

  useEffect(() => {
    setEmail(uc?.context?.email)
  }, []);

  const handleChangePassword = async () => {
    axApiContext(uc, 'PUT', '/user/password', {"password": password1});
    setMessage("Password updated")
    setSnackOpen(true);

  }

  const handleChangeEmail = () => {
  }

  return (
    <Grid container direction="column" spacing={1} margin={0.5}>
      <Grid item>
        <Grid container direction="row" spacing={1} margin={1} alignItems="center">
          <Grid item>
            <Typography variant="h4">
            Account Settings
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{padding: {xs:1, md: 3}, bgcolor: "background.default"}}>
          <Grid container direction="column" spacing={2} margin={1}>
            <Grid item>
              <TextField
                  id="email"
                  label="Email"
                  inputProps={
                    { readOnly: true, }
                  }
                  style = {{width: 300}} 
                  value={email}
                  disabled={true}
              />
            </Grid>
            <Grid item>
              <Button autoFocus onClick={handleChangeEmail} disabled={true} variant="outlined">
                Change Email
              </Button>
            </Grid>
            <Grid item>
              <TextField
                id="password1"
                label="Password"
                type="password"
                style = {{width: 300}} 
                value={password1}
                onChange={e => setPassword1(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id="password2"
                label="Confirm Password"
                type="password"
                style = {{width: 300}} 
                value={password2}
                onChange={e => setPassword2(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Button 
                autoFocus 
                onClick={handleChangePassword} 
                disabled={password1 !== "" && password1 != password2} 
                variant="outlined"
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={snackOpen}
          autoHideDuration={4000}
          onClose={() => {setSnackOpen(false)}}
          message={message}
        />
    </Grid>
  ); 
}

import * as React from 'react';
import { AppBar, Box, Button, InputBase, Toolbar, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useRef, useContext }  from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";

import HamburgerMenu from './HamburgerMenu';
import DialogComingSoon from './DialogComingSoon';
import { SigninDialog } from './SigninDialog';
import { SignupDialog } from './SignupDialog';
import ProfileMenu from './ProfileMenu';
import { UserContext } from 'UserContext';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function TopBar() {  
  const dialogEl = useRef(null);
  const signinEl = useRef(null);
  const signupEl = useRef(null);
  const uc = useContext(UserContext);
  let navigate = useNavigate();
  
  React.useEffect(() => {
  }, [])

  const handleOpenSignin = () => {
    signinEl.current.open();
  }

  const handleOpenSignup = () => {
    signupEl.current.open();
  }

  const signedInUpUpdateHandler = () => {
  }

  return <AppBar position="static">
    <Toolbar variant="dense">
      {uc?.context.loggedIn &&
      <HamburgerMenu></HamburgerMenu>
      }
      {false &&
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
      }
      <Typography variant="h6" component="div" sx={{ flexGrow: 1}} to="/" underline="none">
        <Box onClick={() => {navigate("/")}}>
          Panoramix
        </Box>
      </Typography>
      {uc?.context.loggedIn && 
      <Typography paddingRight={3}>{uc?.context.name} </Typography> }
      {!uc?.context.loggedIn && 
      <Button color="text" variant="text" onClick={handleOpenSignin}>Sign In</Button> }
      {!uc?.context.loggedIn && 
      <Button color="text" variant="outlined" onClick={handleOpenSignup}>Get Started</Button> }
      {uc?.context.loggedIn && 
      <ProfileMenu signedInUpUpdateHandler={signedInUpUpdateHandler}></ProfileMenu>
      }
    </Toolbar>
    <DialogComingSoon ref={dialogEl}></DialogComingSoon>
    <SigninDialog ref={signinEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></SigninDialog>
    <SignupDialog ref={signupEl} signedInUpUpdateHandler={signedInUpUpdateHandler}></SignupDialog>
  </AppBar>;
}

import { Route, Routes, Navigate } from "react-router-dom";
import { Box } from '@mui/material';
import SearchResults from './screens/SearchResults.js'
import Report from './screens/Report'
import Map from './screens/Map.js'
import Follow from './screens/Follow.js'
import Upload from './screens/Upload.js'
import Import from './screens/Import.js'
import OrderHistory from './screens/OrderHistory.js'
import OrderHistoryItem from './screens/OrderHistoryItem.js'
import AboutUs from './screens/AboutUs.js'
import ThreeD from './screens/3d.js'
import Account from './screens/Account.js'
import Profile from './screens/Profile.js'
import MyCollection from './screens/MyCollection.js'
import Cart from './screens/Cart.js'
import Editor from './screens/Editor.js';
import Gallery from './screens/Gallery.js';
import Lightbox from './screens/Lightbox.js';
import HomeNoUser from './screens/HomeNoUser.js'
import Products from './screens/Products.js';
import Product from './screens/Product.js';
import ContactUs from './screens/ContactUs.js';
import Checkout from './screens/Checkout.js';
import {HomeWorker} from './screens/HomeWorker.js';
import { BrowserRouter } from "react-router-dom";
import { useContext, ReactElement }  from 'react';
import { UserContext, userPersonaWorker, userPersonaEndUser } from 'UserContext';
import { propsToClassKey } from "@mui/styles";
import ScrollToTop from "utils/ScrollToTop.js";

import TopBar from 'components/TopBar';
import Footer from 'components/Footer';


export default function Routess(props:any) {

  const uc = useContext(UserContext);

  const ProtectedRoute = (props: any) => {
    if (!uc?.context.loggedIn) {
      return <Navigate to="/" />;
    }
    return (<>
        <TopBar></TopBar>
        <Box minHeight="100vh">
        {props.children}
        </Box>
        <Footer></Footer>
      </>);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        { !uc?.context.loggedIn && 
        <Route path="/" element={<HomeNoUser />} />
        }
        { uc?.context.loggedIn && uc?.context.persona === userPersonaWorker && 
          <Route path="/" element={<ProtectedRoute><HomeWorker /></ProtectedRoute>} />
        }
        { uc?.context.loggedIn && uc?.context.persona === userPersonaEndUser && 
          <Route path="/" element={<ProtectedRoute><Gallery /></ProtectedRoute>} />
        }
        
        <Route path="/aboutus" element={<ProtectedRoute><AboutUs /></ProtectedRoute>} />
        <Route path="/search" element={<ProtectedRoute><SearchResults /></ProtectedRoute>}/>
        <Route path="/search/:searchText" element={<ProtectedRoute><SearchResults /></ProtectedRoute>} />
        <Route path="/contract/:contractAddr" element={<ProtectedRoute><SearchResults /></ProtectedRoute>}  />
        <Route path="/follow" element={<ProtectedRoute><Follow /></ProtectedRoute>}  />
        <Route path="/report" element={<ProtectedRoute><Report /></ProtectedRoute>}  />
        <Route path="/map/:location" element={<ProtectedRoute><Map /></ProtectedRoute>}  />
        <Route path="/upload" element={<ProtectedRoute><Upload /></ProtectedRoute>}  />
        <Route path="/import" element={<ProtectedRoute><Import /></ProtectedRoute>}  />
        <Route path="/orders" element={<ProtectedRoute><OrderHistory /></ProtectedRoute>}  />
        <Route path="/order" element={<ProtectedRoute><OrderHistoryItem /></ProtectedRoute>}  />
        <Route path="/mycollection" element={<ProtectedRoute><MyCollection /></ProtectedRoute>}  />
        <Route path="/3d" element={<ProtectedRoute><ThreeD /></ProtectedRoute>}  />
        <Route path="/cart" element={<ProtectedRoute><Cart /></ProtectedRoute>} />
        <Route path="/account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/gallery" element={<ProtectedRoute><Gallery /></ProtectedRoute>}  />
        <Route path="/lightbox" element={<ProtectedRoute><Lightbox /></ProtectedRoute>}  />
        <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>}  />
        <Route path="/product" element={<ProtectedRoute><Product /></ProtectedRoute>}  />
        <Route path="/editor" element={<ProtectedRoute><Editor /></ProtectedRoute>}  />
        <Route path="/contactus" element={<ProtectedRoute><ContactUs /></ProtectedRoute>}  />
        <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
      </Routes>
    </BrowserRouter>);
}


import * as React from 'react';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogComingSoon from './DialogComingSoon';
import { useState, useRef, useEffect, useContext }  from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from "react-router-dom";
import { CustomThemeContext } from 'theme';
import { UserContext, logout } from 'UserContext.tsx';

export default function ProfileMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dialogEl = useRef(null);
  const { currentTheme, setTheme } = useContext(CustomThemeContext);
  const uc = useContext(UserContext);

  let navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleComingSoon = () => {
    setAnchorEl(null);
    dialogEl.current.open();
  };

  const handleNav = (path) => {
    setAnchorEl(null);
    navigate(path);
  }

  const handleLogout = () => {
    logout(uc);
  }

  useEffect(() => {
  }, []);

  return (
    <div>
      <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
        <ManageAccountsIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>{handleNav("/orders")}}>Order History</MenuItem>
        <MenuItem onClick={()=>{handleNav("/account")}}>Account Settings</MenuItem>
        <MenuItem onClick={()=>{setTheme(currentTheme==="dark" ? 'light' : 'dark'); handleClose();}}>{currentTheme==="dark" ? 'Light' : 'Dark'} Mode</MenuItem>
        <MenuItem onClick={()=>{handleNav("/profile")}}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>

      <DialogComingSoon ref={dialogEl}></DialogComingSoon>
    </div>
  );
}
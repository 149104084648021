import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { forwardRef, useImperativeHandle, useState }  from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from 'react-material-file-upload';

// eslint-disable-next-line
export default DialogUpload = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    open() {
      handleOpenRender();
    }
  }));
  const [openRender, setOpenRender] = useState(false);
  const [files, setFiles] = useState([]);

  const handleOpenRender = () => {
    setOpenRender(true);
  };

  const handleCloseRender = () => {
    setOpenRender(false);
  };

  return (
    <Dialog
      open={openRender}
      onClose={handleCloseRender}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{sx: {width: "40%"}}}
    >
      <DialogTitle id="alert-dialog-title">
        {"Upload Image"}
        <IconButton
          aria-label="close"
          onClick={handleCloseRender}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FileUpload value={files} onChange={setFiles} />
      </DialogContent>
    </Dialog>
  );
});

function DialogUpload(props) {
}
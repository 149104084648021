import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Paper from '@mui/material/Paper';

export default function ContactUs() {
  const body1 = `Thank you for visiting our website.  We are committed to providing our customers with the best possible service, so please don't hesitate to reach out to us with any questions, comments or concerns.

  If you have any questions or need assistance, please email us at:

  `

  const email = "support@panoramix.art"

  const body2 = `
  Please provide your name, phone number, message and one of our experienced team members will get back to you as soon as possible.
    
  Alternatively, you can call us at if you need immediate help:

  +1 (401) 314-3907.
  
  We look forward to hearing from you!`

  return (
    <Grid container direction="column" spacing={1} margin={0}>
      <Grid item>
        <Grid container direction="row" spacing={1} margin={1} alignItems="center">
          <Grid item>
            <Typography variant="h4">
            Contact Us / Support
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} >
        <Paper sx={{padding: {xs:1, md: 3}, marginRight: 2, bgcolor: "background.default"}}>
          <Typography style={{whiteSpace: 'pre-line'}} align="left">
             {body1}
          </Typography>
          <a href={`mailto: ${email}`}>{email}</a>
          <Typography style={{whiteSpace: 'pre-line'}} align="left">
             {body2}
          </Typography>
        </Paper>
      </Grid>
    </Grid>);
}

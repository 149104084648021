import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Paper, Typography, Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';

import { axApiContext, UserContext } from 'UserContext';

function ccyFormat(num) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(num);
}

const label = { inputProps: { 'aria-label': 'Selected' } };

export default function Cart() {
  const [items, setItems] = useState([])
  const [subTotal, setSubtotal] = useState("$0.00")
  const navigate = useNavigate();
  const uc = useContext(UserContext);

  useEffect(() => {
    updateItems();
  }, []);

  const updateItems = async () => {
    const r = await axApiContext(uc, 'GET', '/cart/details');
    setItems(r.items);
    setSubtotal(r.subtotal);
  }

  const updateQuantity = async (value, item) => {
    setItems((prev) => prev.map((_item) => {
      if (_item.uuid===item.uuid) {
        return {..._item, quantity: value}
      } else {
        return _item;
      }
    }));
    if (value==0) {
      await axApiContext(uc, 'DELETE', '/cart/item/' + items[value].uuid);
    } else {
      await axApiContext(uc, 'PUT', '/cart/item', {
        ...item,
        quantity: value,
        product_uuid: item.product.uuid, 
        image_uuids: [item.images[0]?.uuid]
      });
    }
    updateItems();
  }

  return (
    <div>
      <Grid container direction="column" spacing={1} margin={0.5}>
        <Grid item>
          <Box margin={1}>
            <Typography variant="h4">
              Shopping Cart
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <TableContainer component={Paper} sx={{bgcolor: "background.default"}}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {items !== null && items.length > 0 && <TableCell align="right">
                    <Typography variant="h6">Price</Typography>
                  </TableCell> }
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Divider></Divider>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items !== null && items.length > 0 &&
                  items.map((item) => (
                  <TableRow key={item.uuid}>
                    <TableCell>
                      <Box
                        component="img"
                        sx={{
                          height: 100,
                          width: 100,
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                        }}
                        src={item?.images[0]?.url}
                      />
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column" alignContent="center">
                        <Grid item>
                          <Box
                            component="img"
                            sx={{
                              height: 100,
                              width: 100,
                              maxHeight: { xs: 233, md: 167 },
                              maxWidth: { xs: 350, md: 250 },
                            }}
                            src={item.product.showcase_image_url}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>
                            {item.product.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {item.product.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Qty</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={item.quantity}
                          defaultValue={1}
                          label="Qty"
                          onChange={(e) => updateQuantity(e.target.value, item)}
                        >
                          <MenuItem value={0}>Remove</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell align="right">
                      <Typography variant="h7">{ccyFormat(item.product.price * item.quantity)}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
                {items !== null && items.length == 0 &&
                  <TableRow key="0">
                    <TableCell>
                      <Typography>Your cart is empty</Typography>
                    </TableCell>
                  </TableRow>
                }
                <TableRow>
                  <TableCell colSpan={5}>
                    <Divider></Divider>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid container justifyContent="flex-end" sx={{p: 2}}>
              <Typography>Total: {ccyFormat(subTotal)}</Typography>
            </Grid>
            <Grid container justifyContent="flex-end" sx={{p: 2}}>
              <Button variant="contained" disabled={!(items !== null & items.length>0)} type="submit" onClick={() => { navigate("/checkout") }}>Proceed to check out</Button>
            </Grid>
          </TableContainer>
        </Grid>
      </Grid>
  </div>);
}
